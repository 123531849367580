export const wherefromPlaces = [
  {
    id: 1,
    place: "Bowling s Lesbotočem",
  },
  {
    id: 2,
    place: "Knížní Klub",
  },
  {
    id: 3,
    place: "Lesbotoč MUSIC KVÍZ",
  },
  {
    id: 4,
    place: "Prague Pride",
  },
  {
    id: 5,
    place: "Speed Dating",
  },
];

export const ageGroups = [
  {
    id: 1,
    age: "20-25",
  },
  {
    id: 2,
    age: "26-30",
  },
  {
    id: 3,
    age: "31-35",
  },
  {
    id: 4,
    age: "36-40",
  },
  {
    id: 5,
    age: "41-45",
  },
  {
    id: 6,
    age: "46-50",
  },
  {
    id: 7,
    age: "50+",
  },
];
